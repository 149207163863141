.bg_group {
    background-color: #EBF4FB;
    padding-top: 10px;
    margin-bottom: 50px;
    border-bottom: 1px solid #e6ecf0;
    border-top: 1px solid #e6ecf0;
}

.button_create_subtes {
    margin-top: 16px;
}
