body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100%;
}

/* .root {
  height: "100vh";
} */

.logo {
  max-width: 80% !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.main {
  padding-top: 30px;
}

.border_right {
  border-right: 1px solid #e6ecf0;
}

.header_text {
  color: #1779ba;
  font-weight: bold;
}

.boder_bottom {
  border-bottom: 1px solid #e6ecf0;
}

.notification_service_out {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: red;
  color: white;
  width: 100%;
  padding-left: 10px;
}
