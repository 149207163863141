.patient_data {
    margin-bottom: 10px;
}

.patient_edit {
    margin-top: 20px;
}

.button_top{
    margin-top: 24px;
}
