ul {
  list-style-type: none;
}

.padding_test {
  padding-left: 0px;
}

.title_category {
  color: #ffffff;
  background: #2f80ed;
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.test_name {
  padding-left: 5px;
  font-size: 14px;
  color: #5f6a7d;
}

.totales {
  font-size: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.clear_button {
  margin-top: 13px;
}

hr {
  border-top: 1px solid #dddddd;
}

.price {
  color: #2e3b4e;
}

.columns_x {
  column-width: 320px !important;
  column-gap: 15px !important;
  width: 100% !important;
  margin: 50px auto !important;
}

div.columns_x .item {
  background: #fefefe;
  box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
  margin: 0 2px 15px;
  padding-bottom: 10px;
  transition: opacity 0.4s ease-in-out;
  display: inline-block;
  column-break-inside: avoid !important;
  width: 320px !important;
}

div.columns_x .item img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 5px;
}

div.columns_x .item figcaption {
  font-size: 0.9rem;
  color: #444;
  line-height: 1.5;
}

div.columns_x small {
  font-size: 1rem;
  float: right;
  text-transform: uppercase;
  color: #aaa;
}

div.columns_x small a {
  color: #666;
  text-decoration: none;
  transition: 0.4s color;
}

@media screen and (max-width: 750px) {
  .columns_x {
    column-gap: 0px;
  }
  .columns_x .item {
    width: 100%;
  }
}

.padding_test {
  padding-left: 0px;
  padding-right: 0px;
}

.add_button {
  color: #ffffff !important;
  padding-right: 10px;
}

.bp3-popover-target .bp3-button {
  height: 39px !important;
}

.bp3-popover-content {
  max-height: 600px;
}
